export default {
  // notice: please use with SimpleInputMixin!
  // mixins: [SimpleInputMixin],
  inheritAttrs: false,
  props: {
    countryList: {
      type: [Array, Boolean],
      default: false,
    },
  },
  data() {
    return {
      intelPrefixes: [],
    };
  },
  computed: {
    value: {
      get() {
        if (typeof this.modelValue === "object" && this.modelValue) {
          return this.modelValue;
        }

        if (typeof this.modelValue !== "string") {
          return { prefix: null, number: "" };
        }

        if (this.modelValue.startsWith("+")) {
          let number = this.modelValue.slice(4);
          let prefix = this.modelValue.slice(0, 4);
          return { prefix: prefix, number: number };
        }
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    valueHasPrefix() {
      return this.value.startsWith("+");
    },
    prefix: {
      get() {
        if (!this.value) {
          return null;
        }
        return this.value.prefix ?? null;
      },
      set(updatedPrefix) {
        this.$emit("update:modelValue", {
          prefix: updatedPrefix,
          number: this.number,
        });
      },
    },
    number: {
      get() {
        if (!this.value) {
          return null;
        }

        return this.value.number;
      },
      set(updatedNumber) {
        try {
          this.value.number = updatedNumber;
        } catch(e) {

        }
        this.$emit("update:modelValue", {
          prefix: this.prefix,
          number: updatedNumber,
        });
        return true;
      },
    },
    noClassAttrs() {
      let base = { ...this.$attrs };
      delete base.class;
      return base;
    },
  },
  methods: {
    populateCountryList() {
      //import countries
      let countries = utilities.requireAsset("core/data/country-list.json");
      let countryCodes = utilities.requireAsset(
        "core/data/country-code-phone-prefix.json"
      );

      // build intelPrefixes
      this.intelPrefixes = [];

      Object.entries(countries).map((data) => {
        let country = data[1];
        let countryCode = data[0];
        let countryCodePrefix = countryCodes[countryCode] ?? false;

        if (!countryCodePrefix) {
          return;
        }

        if (!countryCodePrefix.startsWith("+")) {
          countryCodePrefix = "+" + countryCodePrefix;
        }

        if (this.countryList && !this.countryList.includes(countryCode)) {
          return;
        }

        this.intelPrefixes.push({
          value: countryCodePrefix,
          label: countryCodePrefix + " " + countryCode + " " + country.emoji,
          emoji: country.emoji,
          countryCode: countryCode,
          countryCodePrefix: countryCodePrefix,
          countryCodePhonePrefix: countryCodePrefix,
          name: country.name,
          image: country.image,
        });

        if (this.intelPrefixes.length === 1) {
          this.$nextTick(() => {
            this.prefix = this.intelPrefixes[0].value;
            this.value = this.prefix + this.number;
          });
        }
      });
    },
  },
  created() {
    this.populateCountryList();
  },
};
